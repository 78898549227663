import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft'
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight'
import { LoadingButton } from '@mui/lab'
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  Stack,
  TextField,
  Typography
} from '@mui/material'
import React, { useState } from 'react'
import ReactPlayer from 'react-player'
import { useNavigate } from 'react-router'
import noPic from '../../../assets/illustrations/im_no-participants.png'
import styles from '../../../style/influencers.module.css'
import { color } from '../../../theme'
import { MessageType, UGCStatus } from '../../../types/types'
import { useResult } from '../../../util'
import agent from '../../../agent'
import { NewInfluencer as Influencer } from '../../../types/types'

interface Props {
  influencer: Influencer
  acceptInfluencer(): Promise<void>
  denyInfluencer(status?: UGCStatus): Promise<void>
}

const NewInfluencerCard: React.FC<Props> = props => {
  const { influencer, acceptInfluencer, denyInfluencer } = props
  const navigate = useNavigate()
  const [currentVidIndex, setCurrentVidIndex] = useState(0)
  const { influencerId, age, stylinkData, email } = influencer
  const { payment, image_urls } = stylinkData
  const { first_name, address, city } = payment
  const [showDialog, setShowDialog] = useState(false)

  const FeedbackDialog = () => {
    const [message, setMessage] = useState('')
    const [sendExampleVideo, setSendExampleVideo] = useState(false)

    // TODO Update this to normal useState
    const [sendingResult, catchLoading] = useResult(null)

    const sendMessage = () =>
      catchLoading(async () => {
        if (message || sendExampleVideo) {
          const { conversationId } = await agent.Chat.createConversation({ influencerId })
          if (message) await agent.Chat.sendMessage({ conversationId, content: message, type: MessageType.text })
          if (sendExampleVideo) {
            const link = 'https://s3.eu-central-1.amazonaws.com/com.freebeeapp/public/webapp_videos/chat_example.mp4'
            await agent.Chat.sendMessage({ conversationId, content: link, type: MessageType.video })
          }
        }
        setShowDialog(false)
        if (denyInfluencer) await denyInfluencer(message ? UGCStatus.New : UGCStatus.Banned)
      })
    return (
      <Dialog open={showDialog} onClose={() => setShowDialog(false)}>
        <DialogTitle>Create conversation</DialogTitle>
        <DialogContent>
          <DialogContentText>{`(Optional) Give feedback to influencer's content`}</DialogContentText>
          <TextField
            autoFocus
            margin='dense'
            id='name'
            label='Feedback'
            type='text'
            fullWidth
            variant='standard'
            value={message}
            onChange={e => setMessage(e.target?.value ?? '')}
          />
          <FormControlLabel control={<Checkbox checked={sendExampleVideo} onChange={e => setSendExampleVideo(e.target.checked)} />} label='Send example video' />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowDialog(false)}>Cancel</Button>
          {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            <LoadingButton variant='contained' onClick={sendMessage} loading={(sendingResult as any).isLoading}>
              Ablehnen
            </LoadingButton>
          }
        </DialogActions>
      </Dialog>
    )
  }

  const renderButtons = () => {
    return (
      <>
        <Button size='small' color='error' variant='contained' onClick={() => setShowDialog(true)}>
          Ablehnen
        </Button>
        <Button size='small' variant='contained' onClick={() => navigate(`/chat/${influencer.influencerId}`)}>
          Chat
        </Button>
        <Button size='small' color='success' variant='contained' onClick={acceptInfluencer}>
          Annehmen
        </Button>
      </>
    )
  }

  const renderVideo = () => {
    if ('defaultContentCV' in influencer && influencer.defaultContentCV?.length) {
      const url = influencer.defaultContentCV[currentVidIndex % influencer.defaultContentCV.length]
      const hasNext = currentVidIndex < influencer.defaultContentCV.length - 1
      const hasPrevious = currentVidIndex > 0
      const pointer = (isNext: boolean, activated: boolean) => {
        const nextVideo = () => setCurrentVidIndex(Math.min(influencer.defaultContentCV!.length - 1, currentVidIndex + 1))
        const lastVideo = () => setCurrentVidIndex(Math.max(0, currentVidIndex - 1))
        const Icon = isNext ? ArrowCircleRightIcon : ArrowCircleLeftIcon
        return <Icon onClick={() => (isNext ? nextVideo() : lastVideo())} sx={{ color: activated ? color.black : 'gray', cursor: activated ? 'pointer' : 'cursor' }} />
      }
      return (
        <Stack>
          <ReactPlayer className={styles.profilePic} controls={true} url={url} height={140} width='100%' />
          {(hasNext || hasPrevious) && (
            <Stack direction='row' mx={3} mt={0.4}>
              {pointer(false, hasPrevious)}
              <Box width='100%' />
              {pointer(true, hasNext)}
            </Stack>
          )}
        </Stack>
      )
    }
  }

  const renderMedia = () => {
    const js = renderVideo()
    const pic = <CardMedia component='img' height='140' width='auto' image={image_urls?.standard?.url || noPic} sx={{ objectFit: 'contain' }} />
    return js == null ? pic : <CardMedia>{js}</CardMedia>
  }

  const checkGiven = (text?: string | number) => text?.toString() || 'nicht angegeben'
  return (
    <>
      <FeedbackDialog />
      <Card sx={{ maxWidth: 400, m: 0.5 }}>
        {renderMedia()}
        <CardContent>
          <Typography gutterBottom variant='h5' component='div'>
            {first_name}
          </Typography>
          <Typography variant='body1' color='text.secondary'>
            {`Alter: ${checkGiven(age)}`}
          </Typography>
          <Typography variant='body1' color='text.secondary' style={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>
            {checkGiven(address)}
          </Typography>
          <Typography variant='body1' color='text.secondary'>
            {checkGiven(city)}
          </Typography>
          <Typography variant='body1' color='text.secondary'>
            {email}
          </Typography>
        </CardContent>
        <CardActions sx={{ justifyContent: 'center' }}>{renderButtons()}</CardActions>
      </Card>
    </>
  )
}

export default NewInfluencerCard
