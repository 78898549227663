import agent from '../agent'

class CampaignService {
  verifyCampaign(campaignId: string, brandId: string, notifyInfluencers: boolean) {
    return agent.Campaigns.verifyCampaign({ campaignId, brandId, notifyInfluencers })
  }

  setPriority(campaignId: string, brandId: string, priority: string) {
    return agent.Campaigns.setPriority({ campaignId, brandId, priority })
  }

  async getCampaigns(status?: 0 | 2) {
    type Element = Exclude<Awaited<ReturnType<typeof agent.Campaigns.getAll>>[number], { applied: number }>
    return (await agent.Campaigns.getAll({ status: status == null ? undefined : `${status}` })) as Element[]
  }

  async getOnlineCampaigns() {
    type Element = Extract<Awaited<ReturnType<typeof agent.Campaigns.getAll>>[number], { applied: number }>
    return (await agent.Campaigns.getAll({ status: '1' })) as Element[]
  }

  getAllUneditedContent() {
    return agent.Campaigns.getAllUneditedContent({})
  }

  sendEditedContent = agent.Campaigns.sendEditedContent
  getContentCheck = agent.Campaigns.getContentCheck
  approveInfluencerContent = agent.Campaigns.approveInfluencerContent
  getTodoOverview = agent.Campaigns.getTodoOverview
  setTodoEndDate = agent.Campaigns.setTodoEndDate
  deleteCampaignInfluencer = agent.Campaigns.deleteCampaignInfluencer
  resetUploadTodo = agent.Todo.resetUploadTodo
  deleteCampaign = agent.Campaigns.deleteCampaign
  setCampaignOffline = agent.Campaigns.setCampaignOffline
  searchCampaign = agent.Campaigns.searchCampaign
  getMediaUploadUrl = agent.Campaigns.getMediaUploadUrl
  getEditedMediaUploadUrl = agent.Campaigns.getEditedMediaUploadUrl
  getCampaignById = agent.Campaigns.getCampaignById
  updateCampaign = agent.Campaigns.updateCampaign
}

export default new CampaignService()
