import React, { useEffect, useState } from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Grid,
  CircularProgress,
  Typography,
  Box,
  FormControlLabel,
  Checkbox,
  Tooltip
} from '@mui/material'
import { Campaign } from '../../types/types'
import CampaignService from '../../service/CampaignService'
import { LoadingButton } from '@mui/lab'
import { enqueueSnackbar } from 'notistack'
import Info from '@mui/icons-material/Info'

type Props = {
  open: boolean
  campaignId: string
  onClose: () => void
}
const CampaignEditDialog = (props: Props) => {
  const [campaign, setCampaign] = useState<Campaign | null>()
  const [loading, setLoading] = useState(true)
  const [loadingSave, setLoadingSave] = useState(false)
  const [notifyBrand, setNotifyBrand] = useState(false)
  const { campaignId, open, onClose } = props

  const fetchCampaign = async () => {
    try {
      const campaign = await CampaignService.getCampaignById({ campaignId })
      setCampaign(campaign)
    } catch (e) {
      console.error(e)
    } finally {
      setLoading(false)
    }
  }

  const handleChangeCampaign = async (data: Campaign) => {
    setCampaign(data)
  }

  const handleChangeScenes = async (value: string, index: number) => {
    const newCampaign = { ...campaign }
    newCampaign.scenes[index] = value
    handleChangeCampaign(newCampaign)
  }

  const handleSaveCampaign = async () => {
    try {
      setLoadingSave(true)
      await CampaignService.updateCampaign({ ...campaign, notifyBrand })
      enqueueSnackbar(`Erfolgreiche Aktualisierung der Kampagne`, { variant: 'success' })
      setNotifyBrand(false)
    } catch (e) {
      console.error(e)
    } finally {
      setLoadingSave(false)
    }
  }

  useEffect(() => {
    fetchCampaign()
    return () => {
      setCampaign(null)
    }
  }, [])

  return (
    <Dialog open={open} onClose={onClose} maxWidth='md' fullWidth>
      <DialogTitle>Edit Campaign: {campaign?.campaignName}</DialogTitle>
      <DialogContent dividers>
        {loading && (
          <Grid justifyContent='center' sx={{ textAlign: 'center' }}>
            <CircularProgress />
            <Typography>Loading Campaign...</Typography>
          </Grid>
        )}
        {!loading && campaign && (
          <Box>
            {campaign.scenes.map((scene: string, index: number) => (
              <TextField
                key={index}
                fullWidth
                margin='normal'
                value={scene}
                multiline
                rows={5}
                name={`scene-${index}`}
                label={`Scene ${index + 1}`}
                onChange={e => handleChangeScenes(e.target.value, index)}
              />
            ))}
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <FormControlLabel control={<Checkbox checked={notifyBrand} onChange={() => setNotifyBrand(!notifyBrand)} />} label='Notify brand' />
              <Tooltip
                title={`Hallo ${campaign.brandName}, euer Storyboard für den Auftrag ${campaign?.productInfo?.name || ''} ist fertig! Bitte schau es dir dies in der Kampagnenübersicht an`}>
                <Info />
              </Tooltip>
            </Box>
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color='secondary' variant='outlined'>
          Cancel
        </Button>
        <LoadingButton loading={loadingSave} disabled={!campaign || loading} onClick={handleSaveCampaign} color='primary' variant='contained'>
          Save Changes
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}

export default CampaignEditDialog
