import React from 'react'
import { ChatPreset } from './UsePresets'
import { Box, Card, CardContent, Grid, IconButton, Tooltip, Typography } from '@mui/material'
import { Remove } from '@mui/icons-material'

interface PresetListProps {
  presets: ChatPreset[]
  loading: boolean
  onSelectPreset: (content: string) => void
  onDeletePreset?: (presetId: string) => void
}

export const PresetList: React.FC<PresetListProps> = ({ presets, loading, onSelectPreset, onDeletePreset }) => {
  return (
    <Grid container spacing={1} marginBottom={2}>
      {presets.length === 0 ? (
        <Grid item xs={12}>
          <Typography variant='body1' align='center'>
            Keine Vorlagen gefunden
          </Typography>
        </Grid>
      ) : (
        presets.map((preset, index) => (
          <Grid item xs={12} sm={6} md={3} key={index}>
            <Card
              variant='outlined'
              sx={{
                borderRadius: 2,
                cursor: 'pointer',
                transition: 'transform 0.2s',
                position: 'relative',
                height: '80px',
                '&:hover': {
                  transform: 'scale(1.03)',
                  boxShadow: 2
                }
              }}
              onClick={() => {
                onSelectPreset(preset.content)
              }}>
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: 2, mt: -1, mr: -2 }}>
                <Typography
                  sx={{
                    fontSize: 15,
                    fontWeight: 'bold',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis'
                  }}>
                  {preset.title}
                </Typography>
                <Tooltip title='Vorlage löschen'>
                  <IconButton
                    size='small'
                    onClick={e => {
                      e.stopPropagation()
                      onDeletePreset?.(preset.presetId)
                    }}
                    disabled={loading}>
                    <Remove color='action' fontSize='small' />
                  </IconButton>
                </Tooltip>
              </Box>
              <CardContent>
                <Typography color='text.secondary' sx={{ mt: -5, fontSize: 12, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                  {preset.content}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))
      )}
    </Grid>
  )
}
