import { Avatar, Button, Chip, CircularProgress, List, ListItemButton, ListItemIcon, ListItemText } from '@mui/material'
import React from 'react'
import { useChat } from '../../context/ChatContext'
import { ChatConversation, MessageType } from '../../types/types'
import MenuDrop from '../todoOverview/MenuDrop'

const ConversationPreview = ({ conversation: convo }: { conversation: ChatConversation }) => {
  const { setSelectedConvo, markAsUnread, selectedConvo } = useChat()
  const { conversationId, brandId, lastMessage, unread } = convo
  const messagePreview =
    lastMessage && `${lastMessage.authorId == brandId ? 'You' : 'Influencer'}: ${lastMessage.type == MessageType.text ? lastMessage.content : 'Media'}`
  const isSelected = conversationId == selectedConvo?.conversationId

  return (
    <ListItemButton key={conversationId} selected={isSelected} onClick={() => setSelectedConvo(convo)}>
      <ListItemIcon>
        {convo.image ? (<img src={convo.image} style={{ borderRadius: '100%', objectFit: 'cover', objectPosition: 'center', height: 60, width: 60, borderColor: 'black' }} />) : (<Avatar sx={{height: 60, width: 60}} />)}
      </ListItemIcon>
      <ListItemText primary={convo.name} secondary={messagePreview} sx={{ ml: 1, textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }} />
      {unread && <Chip label={`NEW`} />}
      {!unread && (
        <MenuDrop options={['Mark as unread']} setOption={() => markAsUnread(conversationId)}>
          <Button size='small'>...</Button>
        </MenuDrop>
      )}
    </ListItemButton>
  )
}

export const ConversationPreviews = () => {
  const { conversations, loadConversations, lastConversationKey } = useChat()
  const hasMore = lastConversationKey !== null
  const gettingConvos = false

  return (
    <List sx={{ width: '100%', bgcolor: 'background.paper', position: 'relative', overflow: 'auto', height: '100%', maxHeight: 700, '& ul': { p: 0 } }}>
      {conversations.map(conversation => (
        <ConversationPreview key={conversation.conversationId} conversation={conversation} />
      ))}
      {gettingConvos ? <CircularProgress /> : hasMore && <Button onClick={() => loadConversations(true)}>Mehr Laden</Button>}
    </List>
  )
}
