import React from 'react'
import { Dialog, DialogActions, DialogContent, DialogTitle, Button } from '@mui/material'
import Carousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'
import { Box } from '@mui/material'

interface Props {
  open: boolean
  videos: string[]
  onClose: () => void
}

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1
  }
}

export const ContentCheckModal: React.FC<Props> = ({ open, videos, onClose }) => {
  return (
    <Dialog open={open} onClose={onClose} maxWidth='md' fullWidth>
      <DialogTitle>Uploaded Videos</DialogTitle>
      <DialogContent>
        <Box
          sx={{
            maxWidth: 800,
            mx: 'auto',
            mt: 4,
            position: 'relative' // For positioning arrows
          }}>
          {/* Swipeable Views */}
          <Carousel responsive={responsive}>
            {videos.map((video, index) => (
              <Box
                key={index}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center'
                }}>
                <video
                  src={video}
                  controls
                  style={{
                    width: '100%',
                    maxHeight: '400px'
                  }}
                />
              </Box>
            ))}
          </Carousel>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          variant='contained'
          onClick={() => {
            onClose()
          }}>
          Schließen
        </Button>
      </DialogActions>
    </Dialog>
  )
}
